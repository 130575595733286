import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0.5em 0 1.4em 0;
`;

export const Image = styled.div`
  height: 90px;
  margin-right: .75em;
  width: 90px;
`;

export const Body = styled.div`
  flex: 1;

  h2 {
    margin: .25em 0 .5em;
  }
`;

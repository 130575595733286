import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { MdClose } from 'react-icons/md';
import * as Modal from './modal.theme';

export default class extends PureComponent {
  static propTypes = {
    children: PropTypes.object,
    closeModal: PropTypes.func,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
  }

  render() {
    const {
      children,
      closeModal,
      isOpen,
      title,
    } = this.props;

    if (!children || !closeModal || !isOpen || !title) return null;

    return (
      <>
        <Modal.Styles />
        <ReactModal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel={title}
          ariaHideApp={false}
          overlayClassName="modal"
          bodyOpenClassName="modal-open"
          className="modal-dialog"
        >
          <Modal.Wrapper>
            <Modal.CloseButton
              type="button"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">
                <MdClose type="close" size="30" />
              </span>
            </Modal.CloseButton>
            <Modal.Body>
              <h2>{ title }</h2>
              { children }
            </Modal.Body>
          </Modal.Wrapper>
        </ReactModal>
      </>
    );
  }
}

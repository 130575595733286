/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { reduxLogs } from 'config/dev';
import promiseMiddleware from 'redux-promise-middleware';
import createReducer from './reducers';

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    thunk,
    promiseMiddleware(),
    routerMiddleware(history),
  ];

  /**
 * Development only middleware
 */
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production') {
  /* eslint-disable global-require */

    // Bug catching, freeze the redux store from mutation during development
    const freeze = require('redux-freeze');
    middlewares.push(freeze);

    // Console logging of redux actions
    const { createLogger } = require('redux-logger');
    const logger = createLogger({
      collapsed: true,
      colors: {
        title: () => 'darkslategray',
        prevState: () => 'darkviolet',
        action: () => 'darkcyan',
        nextState: () => 'green',
        error: () => 'firebrick',
      },
      predicate: () => reduxLogs, // Turn logger on/off easily - controlled via config/dev
    });
    middlewares.push(logger);

  /* eslint-enable */
  }

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = process.env.NODE_ENV !== 'production'
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
      // Prevent recomputing reducers for `replaceReducer`
      shouldHotReload: false,
    })
    : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS({ ...initialState }),
    composeEnhancers(...enhancers)
  );

  // Extensions
  store.injectedReducers = {}; // Reducer registryry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { FiCamera } from 'react-icons/fi';
import Icon from 'components/Icon';
import { Navbar, NavContainer, NavItem } from './theme';

export default class BottomMenu extends PureComponent {
  static propTypes = {
    active: PropTypes.string,
    name: PropTypes.string,
    userid: PropTypes.string,
  };

  handleGA = () => {
    ReactGA.event({
      category: 'Outbound Link',
      action: 'Clicked menu Map button',
      label: 'Map icon',
    });
  };

  render() {
    const { active, name, userid } = this.props;
    return (
      <>
        <Navbar>
          <NavContainer>
            <Link to={{ pathname: '/', search: `?name=${name}&userid=${userid}` }}>
              <NavItem className={active === 'dashboard' && 'is-active'}>
                <Icon type="shield" size="1.6em" />
              </NavItem>
            </Link>

            <Link to={{ pathname: '/scan', search: `?name=${name}&userid=${userid}` }}>
              <NavItem className={`is-large ${active === 'scan' && 'is-active'}`}>
                <FiCamera size="2em" />
              </NavItem>
            </Link>

            <a
              href="delltechworld:///html?page=8101&title=My%20Dell%20AR%20Experience"
              onClick={() => this.handleGA()}
            >
              <NavItem>
                <Icon type="map" size="1.6em" />
              </NavItem>
            </a>
          </NavContainer>
        </Navbar>
      </>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class extends PureComponent {
  static propTypes = {
    size: PropTypes.string,
  }

  render() {
    const { size } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 56" width={size} height={size}>
        <path d="M40.25,20.24C40.25,10,31.63,1.75,21,1.75S1.75,10,1.75,20.24a17.9,17.9,0,0,0,2.76,9.54L21,54.25,37.48,29.77A18,18,0,0,0,40.25,20.24Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5px" />
        <ellipse cx="21" cy="20.16" rx="8.2" ry="7.87" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5px" />
      </svg>
    );
  }
}

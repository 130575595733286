import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import * as Layout from 'components/Layout';
import TriggerCard from 'components/TriggerCard';
import TriggerModal from 'components/TriggerModal';
import BottomMenu from 'components/BottomMenu';
import TopMenu from 'components/TopMenu';
import Modal from 'components/Modal';
import ReactGA from 'react-ga';
import triggerData from 'config/triggers';
import { dashboardText } from 'config/text';

export default class Dashboard extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
  };

  constructor() {
    super();
    this.isLocked = this.isLocked.bind(this);

    this.state = {
      userid: '',
      name: '',
      modal: {
        isOpen: false,
        title: '',
        img: '',
        body: [],
        cta: {
          label: '',
          url: '',
        },
        key: '',
      },
      targetOne: 0,
      targetTwo: 0,
      targetThree: 0,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
  }

  componentWillMount() {
    const { location: { search } } = this.props;
    const { userid, name } = queryString.parse(search);


    // Retrieve the object from storage
    let storageObject = JSON.parse(localStorage.getItem(`DellAR${userid}`));

    if (storageObject === null || storageObject.userid !== userid) {
      storageObject = {
        userid, name, targetOne: 0, targetTwo: 0,
      };
    }

    // Put the object into storage
    localStorage.setItem(`DellAR${userid}`, JSON.stringify(storageObject));
    this.setState(storageObject);
  }

  getTarget(key) {
    const {
      targetOne, targetTwo, targetThree,
    } = this.state;

    switch (key) {
      case 'laptop': return targetOne;
      case 'cloud-city': return targetTwo;
      case 'mclaren': return targetThree;
      default: return null;
    }
  }

  isLocked(targetState) {
    return targetState ? 'UnLocked' : 'Locked';
  }

  handleCloseModal() {
    this.setState({ modal: { isOpen: false } });
  }

  handleCardClick(trigger) {
    this.setState({
      modal: {
        isOpen: true,
        title: trigger.modalTitle,
        subtitle: trigger.modalSubtitle,
        img: trigger.modalImg,
        body: trigger.modalBody,
        cta: {
          label: trigger.modalCTA.label,
          url: trigger.modalCTA.url,
        },
        key: trigger.key,
      },
    });


    ReactGA.event({
      category: 'Internal Link',
      action: `Clicked ${trigger.modalTitle} Card`,
      label: 'Modals',
    });
  }

  renderModal() {
    const { modal } = this.state;

    return (
      <Modal
        closeModal={this.handleCloseModal}
        isOpen={modal.isOpen}
        title={modal.title}
      >
        <TriggerModal
          title={modal.title}
          subtitle={modal.subtitle}
          img={modal.img}
          body={modal.body}
          cta={modal.cta}
          isLocked={this.isLocked(this.getTarget(modal.key))}
        />
      </Modal>
    );
  }

  render() {
    const { userid, name } = this.state;

    if (userid === '' || userid === null) return <Redirect to="/usernotfound" />;

    return (
      <>
        <Layout.ScrollWrapper>
          <Helmet>
            <title>Dell AR Web Experience</title>
          </Helmet>

          <Layout.Wrapper>
            <TopMenu userid={userid} name={name} />
            <h1>{ dashboardText.title }</h1>

            <p style={{ marginBottom: '1.5em' }}>
              { name !== 'undefined' ? `${dashboardText.welcome} ${name}.` : 'Welcome!' }
              &nbsp;
              { dashboardText.body }
            </p>

            <Layout.Section>
              {
                triggerData.map((trigger) => (
                  <TriggerCard
                    key={trigger.title}
                    isLocked={this.isLocked(this.getTarget(trigger.key))}
                    trigger={trigger}
                    target={this.getTarget(trigger.key)}
                    onClick={this.handleCardClick}
                  />
                ))
              }
            </Layout.Section>
            <BottomMenu userid={userid} name={name} active="dashboard" />
          </Layout.Wrapper>
        </Layout.ScrollWrapper>

        { this.renderModal() }
      </>
    );
  }
}

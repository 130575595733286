import laptopImg from 'assets/images/Laptop_thumbnail.png';
import mclarenImg from 'assets/images/McLaren_thumbnail.png';
import cloudcityImg from 'assets/images/Cloud_City_thumbnail.png';

export default [
  {
    key: 'laptop',
    title: 'Latitude 7400 2-in-1',
    body: 'The ThinkPad killer is here.',
    modalTitle: 'Latitude 7400 2-in-1',
    modalSubtitle: 'Work at full speed​​​​​​​',
    modalImg: laptopImg,
    modalBody: [
      'Virtually hold the new Latitude 7400 2-in-1 in your hand and explore the world’s smallest 14" commercial 2-in-1 featuring Dell ExpressSign-in, the first PC proximity sensor enabled by Intel® Context Sensing Technology and up to 24 hours of battery life.',
      'Find it at the Latitude booth 1223 located on the Expo Floor.',
    ],
    modalCTA: {
      label: 'View Map',
      url: 'delltechworld:///html?page=8101&title=My%20Dell%20AR%20Experience',
    },
  },
  {
    key: 'cloud-city',
    title: 'Cloud City',
    body: 'In Cloud City, every organization experiences progress.',
    modalTitle: 'Cloud City',
    modalSubtitle: 'Cloud Solutions at work',
    modalImg: cloudcityImg,
    modalBody: [
      '​​​​​​​Connected cities or ‘smart cities’ mix technology and human capital to solve problems resulting from the growth of the urban population. Learn about Dell\'s solutions for Cloud Computing. See an Augmented Reality 3D model of a smart city in action.',
      'Find it at the Cloud City booth 619 located on the Expo Floor.',
    ],
    modalCTA: {
      label: 'View Map',
      url: 'delltechworld:///html?page=8101&title=My%20Dell%20AR%20Experience',
    },
  },
  {
    key: 'mclaren',
    title: 'McLaren Customer Story',
    body: 'See how McLaren maximizes race performance.',
    modalTitle: 'Customer Awards',
    modalSubtitle: 'McLaren Customer Story',
    modalImg: mclarenImg,
    modalBody: [
      'Our Dell Technologies World customer award recipients are chosen based on their innovation with Dell product and services.',
      'With the help of Dell Technologies, McLaren Motorsport is able to simulate race car and track conditions, so the driver and team are prepared for anything. Explore the virtual F1 race car that gives Team McLaren an edge all the way to the finish line.',
      'Find it at the McLaren booth 549 located on the Expo Floor.',
    ],
    modalCTA: {
      label: 'View Map',
      url: 'delltechworld:///html?page=8101&title=My%20Dell%20AR%20Experience',
    },
  },
];

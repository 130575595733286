import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactGA from 'react-ga';
import Icon from 'components/Icon';
import * as A from 'components/A';
import * as TriggerModal from './triggermodal.theme';

export default class extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    img: PropTypes.string,
    body: PropTypes.array,
    cta: PropTypes.object,
    isLocked: PropTypes.string,
  };

  componentDidMount() {
    const { title } = this.props;
    ReactGA.modalview(title);
  }

  handleGA = () => {
    ReactGA.event({
      category: 'Outbound Link',
      action: 'Clicked modal view map button',
      label: 'View Map',
    });
  };

  render() {
    const {
      title,
      subtitle,
      img,
      body,
      cta,
      isLocked,
    } = this.props;

    const badgeClass = isLocked === 'Locked' && 'is-locked';
    const badgeText = isLocked === 'Locked' ? 'Not Found' : 'Found';

    return (
      <>
        <TriggerModal.BadgeContainer className={badgeClass}>
          <TriggerModal.Badge className={badgeClass}>
            <Icon type="shield" size="1.6em" />
          </TriggerModal.Badge>
          <p>{ badgeText }</p>
        </TriggerModal.BadgeContainer>

        <TriggerModal.Image src={img} alt={title} />
        <h4>{ subtitle }</h4>
        {
          body && body.length && body.map((text, i) => {
            const marginBottom = i + 1 === body.length ? '3em' : '1.5em';
            return (<p key={text} style={{ marginBottom }}>{ text }</p>);
          })
        }
        {
          cta && (
            <A.Default
              href={cta.url}
              onClick={() => this.handleGA()}
            >
              { cta.label }
            </A.Default>
          )
        }
      </>
    );
  }
}

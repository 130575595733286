export const dashboardText = {
  title: 'Find the AR triggers',
  welcome: 'Welcome,',
  body: 'Find the three AR triggers to see cool experiences! Here\'s your scorecard to keep track.',
};

export const helpText = {
  title: 'How to use Web AR',
  body: [
    'This experience uses Augmented Reality via a browser experience. You\'ll need to use your native camera to scan the AR markers to see the unlocked content. No app download is needed. App requirements include devices running internet browsers of Safari for iOS, and Chrome for Android.',
    'Step 1: Find the markers on your map. You may be notified via beacon if you are close by.',
    'Step 2: Use the camera on your phone to scan the trigger in the black box. Marker must remain in centre area of camera viewfinder to have AR object appear.',
    'Step 3: Interact as directed to spin, tap, or zoom the content and learn more.',
    'Step 4: Once you have completed the AR Adventurer badge, you will be able to take that completed badge and show them on your phone at the Registration booth in the Village. Find the badges scorecard under the My Game in the side menu of the app. Redeem for a chance at winning one of 30 prizes: Bose Revolve Speaker, Ring Video Doorbell or Alienware Gaming Headset. ',
  ],
};

export const infoText = {
  title: 'About this App',
  body: [
    'This app encourage attendees to explore the event floor by offering unique Augmented Reality experiences located at various strategic but scattered locations. to enable you to learn more about Dell Technologies innovative products and partnerships.',
    'Created by the <a href="https://intergalactic.com/" style="decoration: none;">Intergalactic Agency</a> team, this extension of the conference app uses WebAR with a simple augmented reality library directly in the browser that is compatible with all modern mobile devices. This is essentially a Javascript framework acting as a port of ARToolkit.',
    'As the attendee explores the event floor they are encouraged to scan and capture targets that trigger unique AR experiences to play on their device. With each captured trigger the experience is added to the Event app badge / accomplishment gallery.',
    'Collect them all to enter our prize contest.',
  ],
};

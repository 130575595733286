import React, { Component } from 'react';
import AppRouter from 'router';

class MainApp extends Component {
  // constructor() {
  //   super();
  // }
  render() {
    return (
      <AppRouter />
    );
  }
}

export default MainApp;

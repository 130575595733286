import { css } from 'styled-components';

export default {
  mobile: (...args) => css`
    @media (max-width: 736px) {
      ${css(...args)}
    }
  `,
  small: (...args) => css`
    @media (max-width: 900px) {
      ${css(...args)}
    }
  `,
  medium: (...args) => css`
    @media (max-width: 1070px) {
      ${css(...args)}
    }
  `,
};

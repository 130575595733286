import { createGlobalStyle } from 'styled-components';
import ReplicaProRegularWoff from 'assets/fonts/ReplicaPro-Regular.woff';
import ReplicaProRegularOtf from 'assets/fonts/ReplicaPro-Regular.otf';
import ReplicaProRegularEot from 'assets/fonts/ReplicaPro-Regular.eot';
import ReplicaProRegularSvg from 'assets/fonts/ReplicaPro-Regular.svg';
import ReplicaProRegularTtf from 'assets/fonts/ReplicaPro-Regular.ttf';
import ReplicaProLightWoff from 'assets/fonts/ReplicaPro-Light.woff';
import ReplicaProLightOtf from 'assets/fonts/ReplicaPro-Light.otf';
import ReplicaProLightEot from 'assets/fonts/ReplicaPro-Light.eot';
import ReplicaProLightSvg from 'assets/fonts/ReplicaPro-Light.svg';
import ReplicaProLightTtf from 'assets/fonts/ReplicaPro-Light.ttf';

export const FontFaces = createGlobalStyle`
  @font-face {
    font-family: 'ReplicaPro';
    src:  url('${ReplicaProRegularEot}') format('embedded-opentype'),
          url('${ReplicaProRegularOtf}')  format('opentype'),
          url('${ReplicaProRegularWoff}') format('woff'),
          url('${ReplicaProRegularTtf}')  format('truetype'),
          url('${ReplicaProRegularSvg}') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'ReplicaPro';
    src:  url('${ReplicaProLightEot}') format('embedded-opentype'),
          url('${ReplicaProLightOtf}')  format('opentype'),
          url('${ReplicaProLightWoff}') format('woff'),
          url('${ReplicaProLightTtf}')  format('truetype'),
          url('${ReplicaProLightSvg}') format('svg');
    font-weight: 300;
    font-style: normal;
  }
`;

export default {
  body: `
    font-family: 'ReplicaPro', Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
  `,

  h1: `
    font-size: 40px;
    font-weight: normal;
    line-height: 1.1;
  `,

  h1Mobile: `
    font-weight: normal;
    font-size: 30px;
    line-height: 1.1;
  `,

  h2: `
    font-size: 32px;
    font-weight: normal;
    line-height: 1.1;
  `,

  h2Mobile: `
    font-size: 20px;
    font-weight: normal;
    line-height: 1.1;
  `,

  h3: `
    font-size: 28px;
    font-weight: normal;
    line-height: 1.1;
  `,

  h3Mobile: `
    font-size: 22px;
    font-weight: normal;
  `,

  h4: `
    font-size: 24px;
    font-weight: normal;
  `,

  h4Mobile: `
    font-size: 20px;
    font-weight: normal;
  `,

  h5: `
    font-size: 20px;
    font-weight: normal;
  `,

  h5Mobile: `
    font-size: 18px;
    font-weight: normal;
  `,

  small: `
    font-size: 14px;
  `,
};

import styled, { keyframes } from 'styled-components';
import fonts from './fonts';

const revealWrapper = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const Center = styled.div`
  margin: 10vh auto;
  text-align: center;
`;

export const H1 = styled.h1`
  ${fonts.heading};
  padding-top: ${(props) => props.top || 0};
`;

export const Heading = styled.div`
  ${fonts.heading};
  font-size: 24px;
  line-height: 1.25;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: ${(props) => props.top || 0};
`;

export const FormWrapper = styled.div`
  &.showResults {
    animation: ${revealWrapper} 750ms ease-in-out;
  }
`;

export const Form = styled.form`
  font-size: 18px;
  line-height: 1.25;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;

  & > ul {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 40px auto 0;
    text-align: left;

    li {
      margin-bottom: 20px;
    }
  }

  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`;

export const Radio = styled.div`
  background-color: white;
  border: 1px solid ${(props) => props.active ? 'green' : 'blue'};
  border-radius: 50%;
  flex: 0 0 auto;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;

  &:before {
    background-color: ${(props) => props.active ? 'green' : 'transparent'};
    border-radius: 50%;
    bottom: 3px;
    content: '';
    display: block;
    left: 3px;
    position: absolute;
    right: 3px;
    top: ${(props) => props.active ? '3px' : '-100%'};
    transform: ${(props) => props.active ? 'scaleX(1)' : 'scaleX(0)'};
    transition: background-color 250ms ease, border-color 250ms ease, top 250ms ease, transform 250ms ease;
  }

  input[type="radio"] {
    display: none;
  }

  &,
  & + span {
    display: inline-block;
    vertical-align: middle;
  }

  & + span {
    margin-left: 20px;
    position: relative;
    top: 2px;
  }
`;

import styled, { createGlobalStyle } from 'styled-components';
import animate from 'theme/animate';
import zIndex from 'theme/zIndex';

/*
 * For use with react-modal library.
*/
export const Styles = createGlobalStyle`
  ${animate.fadeIn}
  body.modal-open {
    overflow: hidden;

    .modal {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .modal {
    animation: fadein 0.2s ease-in;
    background: rgba(0,0,0,.8);
    display: flex;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    outline: 0;
    width: 100%;
    z-index: ${zIndex.modal};
  }

  .modal-dialog {
    margin: auto;
    outline: 0;
  }
`;

export const Wrapper = styled.div`
  background: #fff;
  border-radius: 2em;
  box-shadow: 0 1px 10px rgba(0,0,0,0.05), 0 1px 10px rgba(0,0,0,0.05);
  margin: 1rem;
  max-width: ${(props) => props.maxWidth ? `${props.maxWidth}px` : '500px'};
  position: relative;
`;

export const CloseButton = styled.button`
  background: transparent;
  right: 1.5em;
  padding: 0;
  position: absolute;
  top: 2em;

  svg {
    fill: ${(props) => props.theme.modalButtonColor};
    transition: fill .2s ease;
  }

  &.is-inverse {
    svg {
      fill: #fff;
    }
  }
`;

export const Body = styled.div`
  padding: 7.5rem 1.5rem 3rem;

  h2 {
    font-size: 2em;
  }

  h4 {
    margin-bottom: .5em;
  }

  p {
    line-height: 1.5em;
    margin-bottom: 1em;
  }

  button {
    margin: .5em 0;
  }
`;

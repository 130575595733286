import { captureException, captureMessage } from '@sentry/browser';

// Send error to Sentry for tracking
export function logError(error, level, context) {
  if (error instanceof Error) {
    captureException(error, {
      level: level || 'error',
      extra: context || null,
    });
  } else {
    captureMessage(error, {
      level: level || 'error',
      extra: context || null,
    });
  }
  console.error(error);
}

export default logError;

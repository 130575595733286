import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactGA from 'react-ga';
import { FiChevronLeft } from 'react-icons/fi';
import Icon from 'components/Icon';
import * as TopMenu from './topmenu.theme';
import { TopItems, TopItem } from './theme';

export default class BottomMenu extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    userid: PropTypes.string,
    scanpage: PropTypes.number,
    targetOne: PropTypes.number,
    targetTwo: PropTypes.number,
    targetThree: PropTypes.number,
    notsupported: PropTypes.bool,

  };

  getUnlocked() {
    const { targetOne, targetTwo, targetThree } = this.props;
    return targetOne + targetTwo + targetThree;
  }

  handleGA = () => {
    ReactGA.event({
      category: 'Outbound Link',
      action: 'Clicked back to app button',
      label: 'Back to App',
    });
  };

  handleGAInternal = (paramater) => {
    ReactGA.event({
      category: 'Internal Link',
      action: `Clicked ${paramater} button`,
      label: `${paramater} Button`,
    });
  };

  isLocked(targetState) {
    return targetState ? 'UnLocked' : 'Locked';
  }

  showBagdes() {
    const { targetOne, targetTwo, targetThree } = this.props;

    return (
      <div>
        <TopItems>
          <TopItem className={targetOne && 'is-active'}>
            <Icon type="shield" size="1.0em" strokeColour={targetOne ? '#fff' : '#bfbfbf'} />
          </TopItem>
          <TopItem className={targetTwo && 'is-active'}>
            <Icon type="shield" size="1.0em" strokeColour={targetTwo ? '#fff' : '#bfbfbf'} />
          </TopItem>
          <TopItem className={targetThree && 'is-active'}>
            <Icon type="shield" size="1.0em" strokeColour={targetThree ? '#fff' : '#bfbfbf'} />
          </TopItem>
        </TopItems>
      </div>
    );
  }

  renderBackButton() {
    const { name, userid, scanpage } = this.props;

    if (scanpage) {
      return (
        <TopMenu.A
          href={`/?name=${name}&userid=${userid}`}
          onTouchStart={() => {
            window.location.href = `/?name=${name}&userid=${userid}`;
            this.handleGAInternal('Home');
          }
          }
          onMouseDown={() => {
            window.location.href = `/?name=${name}&userid=${userid}`;
            this.handleGAInternal('Home');
          }
          }
          className={scanpage ? 'scanPage' : ''}
        >
          <FiChevronLeft className="chevron" />
            Home
        </TopMenu.A>
      );
    }
    return (
      <TopMenu.A
        href="delltechworld:///html?page=8036&title=My%20Dell%20AR%20Experience"
        onTouchStart={() => {
          window.location.href = 'delltechworld:///html?page=8036&title=My%20Dell%20AR%20Experience';
          this.handleGA();
        }
        }
        onMouseDown={() => {
          window.location.href = 'delltechworld:///html?page=8036&title=My%20Dell%20AR%20Experience';
          this.handleGA();
        }
        }
        className={scanpage ? 'scanPage' : ''}
      >
        <FiChevronLeft className="chevron" />
      Back to App
      </TopMenu.A>
    );
  }

  render() {
    const {
      name, userid, scanpage, notsupported,
    } = this.props;
    return (
      <>
        <TopMenu.Wrapper className={scanpage ? 'scanPage' : ''}>
          <div style={{ flexBasis: '33.33%' }}>
            {this.renderBackButton()}
          </div>

          {scanpage && this.showBagdes()}

          <div style={{ flexBasis: '33.33%' }}>
            {notsupported ? ''

              : (
                <TopMenu.AHelp
                  href={`/help?name=${name}&userid=${userid}`}
                  onTouchStart={() => {
                    window.location.href = `/help?name=${name}&userid=${userid}`;
                    this.handleGAInternal('Help');
                  }
                  }
                  onMouseDown={() => {
                    window.location.href = `/help?name=${name}&userid=${userid}`;
                    this.handleGAInternal('Help');
                  }
                  }
                  className={scanpage ? 'scanPage' : ''}
                >
            Help
                </TopMenu.AHelp>
              )
            }

          </div>
        </TopMenu.Wrapper>
      </>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class extends PureComponent {
  static propTypes = {
    size: PropTypes.string,
    strokeColour: PropTypes.string,
  }

  render() {
    const { size, strokeColour } = this.props;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.6 59.2" width={size} height={size}>
        <path d="M45.6,8.3C40.7,3.6,34,1.5,23.7,1.5s-17,2-22,6.8l-.2.3V29.4c0,12.7,8.8,24.1,22,28.2l.3.1.3-.1c13.1-4.1,22-15.4,22-28.2V8.6Z" fill="none" stroke={strokeColour || '#fff'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" />
        <path d="M45.8,18.7c-4.9-4.7-11.7-6.8-22-6.8s-17,2-22,6.8l-.3.3" fill="none" stroke={strokeColour || '#fff'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" />
        <polygon points="26.7 28.2 23.7 21.3 20.7 28.2 13.2 28.8 18.9 33.7 17.2 40.9 23.7 37.1 30.2 40.9 28.5 33.7 34.2 28.8 26.7 28.2" fill="none" stroke={strokeColour || '#fff'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5px" />
      </svg>
    );
  }
}

import styled from 'styled-components';
import colors from 'theme/colors';
import { Link } from 'react-router-dom';

export const Default = styled.a`
  background: ${colors.brandPrimary};
  border-radius: .8em;
  color: #fff;
  display: block;
  font-size: 1em;
  letter-spacing: .5px;
  margin: .75em auto;
  padding: .75em;
  text-align: center;

  &.is-outline {
    background: transparent;
    color: ${colors.brandPrimary};
    border: 1px solid ${colors.brandPrimary};
  }
`;

export const RouterLink = styled(Link)`
  background: ${colors.brandPrimary};
  border-radius: .8em;
  color: #fff;
  display: block;
  font-size: 1em;
  letter-spacing: .1px;
  margin: .75em auto;
  padding: .75em;
  text-align: center;

  &.is-outline {
    background: transparent;
    color: ${colors.brandPrimary};
    border: 1px solid ${colors.brandPrimary};
  }
`;

import styled from 'styled-components';
import colors from 'theme/colors';
import zIndex from 'theme/zIndex';

export const ScrollWrapper = styled.div`
  background: ${colors.grayLight};
  color: #000;
  height: 100vh;
  overflow: auto;
  width: 100%;
  z-index: ${zIndex.layout};
`;

export const Wrapper = styled.div`
  height: auto;
  padding: 4em 2em 10em;
`;

export const Section = styled.section`
  background: #fff;
  border-radius: 2em;
  margin: 0 -1em;
  padding: 1em 1.3em;
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'theme/colors';
import zIndex from 'theme/zIndex';

export const Wrapper = styled.div`
  background: ${colors.grayLight};
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 1em 2em 1em;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: ${zIndex.menu};

  &.scanPage {
    background: ${colors.black};
    opacity:0.8;
  }
`;

export const A = styled.a`
  /* border-bottom: 1px solid #ccc; */
  color: #000;
  font-size: .85em;
  text-decoration: none;
  position: relative;
  left: .5em;


  .chevron {
    left: -1.2em;
    position: absolute;
    top: .2em;
  }
  top: .2em;
  text-decoration:underline;

  &:focus,
  &:hover,
  &:active {
    /* text-decoration: none; */
  }

  &.scanPage {
    color: ${colors.white};
  }
`;

export const RouterLink = styled(Link)`
  border-bottom: 1px solid #ccc;
  color: #000;
  font-size: .75em;
  float: right;

  &.scanPage {
    color: ${colors.white};
  }
`;

export const AHelp = styled.a`
  /* border-bottom: 1px solid #ccc; */
  color: #000;
  font-size: .85em;
  float: right;
  text-decoration:underline;
  padding-top: 6px;
  &:focus,
  &:hover,
  &:active {
    /* text-decoration: none; */
    text-decoration:underline!important;
  }

  &.scanPage {
    color: ${colors.white};
  }
`;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import LaptopNotFound from 'assets/icons/laptop-trigger-not-found.svg';
import Laptop from 'assets/icons/laptop-trigger.svg';
import CloudCityNotFound from 'assets/icons/cloud-city-trigger-not-found-2.svg';
import CloudCity from 'assets/icons/cloud-city-trigger-found-2.svg';
import McLarenNotFound from 'assets/icons/mclaren-trigger-not-found.svg';
import McLaren from 'assets/icons/mclaren-trigger.svg';

import * as TriggerCard from './triggercard.theme';

export default class extends PureComponent {
  static propTypes = {
    isLocked: PropTypes.string,
    trigger: PropTypes.object,
    onClick: PropTypes.func,
  };

  getBadge(key) {
    switch (key) {
      case 'laptop':
        return { found: Laptop, notFound: LaptopNotFound };
      case 'cloud-city':
        return { found: CloudCity, notFound: CloudCityNotFound };
      case 'mclaren':
        return { found: McLaren, notFound: McLarenNotFound };
      default:
        return null;
    }
  }

  render() {
    const { isLocked, trigger, onClick } = this.props;
    const icon = this.getBadge(trigger.key);
    const image = isLocked === 'Locked' ? icon.notFound : icon.found;

    return (
      <TriggerCard.Wrapper onClick={() => onClick(trigger)}>
        <TriggerCard.Image>
          <img src={image} alt={trigger.title} />
        </TriggerCard.Image>
        <TriggerCard.Body>
          <h2>{ trigger.title }</h2>
          <p>
            { trigger.body }
          </p>
        </TriggerCard.Body>
      </TriggerCard.Wrapper>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Shield from './icons/Shield';
import Map from './icons/Map';

class Icon extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
  }

  render() {
    const { type } = this.props;
    switch (type.toLowerCase()) {
      case 'map':
        return (<Map {...this.props} />);
      case 'shield':
        return (<Shield {...this.props} />);
      default:
        return null;
    }
  }
}

export default Icon;

import styled from 'styled-components';
import colors from 'theme/colors';
import zIndex from 'theme/zIndex';

export const Navbar = styled.div`
  bottom: 0;
  padding: 0 3em;
  position: fixed;
  right: 0;
  left: 0;
  z-index: ${zIndex.menu};
`;

export const NavContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;

  a {
    margin: 1em;
  }
`;

export const NavItem = styled.div`
  align-items: center;
  background: ${colors.brandPrimary};
  box-shadow: 0 1px 5px 3px rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.2);
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  height: 45px;
  text-align: center;
  transition: background .5s ease;
  width: 45px;

  &.is-large {
    height: 65px;
    width: 65px;

    svg {
      position: relative;
      top: -2px;
    }
  }

  &.is-active {
    background: ${colors.brandPrimaryHover};
  }
`;

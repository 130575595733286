import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Center } from 'theme/misc';

export default class NotFound extends PureComponent {
  render() {
    return (
      <>
        <Helmet>
          <title>Sorry, this page doesn&apos;t exist.</title>
          <meta name="description" content="Sorry, this page doesn't exist" />
        </Helmet>

        <Center>
          <h2>
            Sorry, something went wrong! :(
            <br />
            <br />
              This page doesn&apos;t exist.
          </h2>
        </Center>
      </>
    );
  }
}

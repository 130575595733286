export default {
  // Project Colours
  brandPrimary: '#0076CE',
  brandPrimaryHover: '#1D5786',
  grayLight: '#f7f7f7',
  white: '#fff',
  black: '#191919',

  // Social
  facebookBlue: '#3b5998',
  linkedinBlue: '#0077b5',
  instagramPurple: '#833ab4',
};

/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

/* eslint-disable import/first */
// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import reset css & CSS reset and Global Styles
import 'sanitize.css/sanitize.css';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';

// Import root app
import MainApp from 'containers/App';

import {
  baseElement,
  sentry_dsn,
  productionHostName,
  stagingHostName,
  googleAnalyticsID,
} from 'config';
import { whyUpdate } from 'config/dev';
import { getEnvironment } from 'utils';
import { version } from '../package.json';

/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./assets/images/favicon.ico';
/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore';

const isProduction = window.location.host === productionHostName;
const isStaging = window.location.host === stagingHostName;

// Import Sentry
if ((isProduction || isStaging)) {
  console.info(`Release ${version}`);
  Sentry.init({
    dsn: sentry_dsn,
    release: version,
    environment: getEnvironment(window.location.hostname),
  });
}

// WhyDidYouUpdate throws a message when a potential unnessessary rerender occurs
// https://github.com/maicki/why-did-you-update#readme
if (process.env.NODE_ENV !== 'production' && whyUpdate) {
  const { whyDidYouUpdate } = require('why-did-you-update'); // eslint-disable-line global-require
  whyDidYouUpdate(React, { groupByComponent: true, collapseComponentGroups: true });
}

// Create redux store with history
const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
const MOUNT_NODE = document.querySelectorAll(`${baseElement}`)[0];

// Google Analytics
ReactGA.initialize(googleAnalyticsID);
ReactGA.pageview(history.location.pathname);
history.listen((location) => {
  ReactGA.set({
    location: window.location.href,
    page: location.pathname,
  });
  ReactGA.pageview(location.pathname);
});

// Wrap the App in Redux, Apollo and React Router
const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MainApp />
    </ConnectedRouter>
  </Provider>
);

if (module.hot) {
  module.hot.accept(['./router'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    ReactDOM.render(<App />, MOUNT_NODE);
  });
}

ReactDOM.render(<App />, MOUNT_NODE);

import React, {
  Fragment,
  StrictMode,
  Suspense,
  lazy,
} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GlobalStyles } from 'theme/globalStyles';
import { FontFaces } from 'theme/fonts';
import NotFoundPage from 'containers/NotFoundPage';
import Dashboard from 'containers/Dashboard';
import UserNotFound from 'containers/UserNotFoundPage';
import { reactStrictMode } from 'config/dev';
import { getEnvironment } from 'utils';


// Page imports
import ErrorBoundary from 'components/ErrorBoundary';
const ScanPage = lazy(() => import('containers/ScanPage'));
const InfoPage = lazy(() => import('containers/InfoPage'));
const HelpPage = lazy(() => import('containers/HelpPage'));
const NotSupported = lazy(() => import('containers/NotSupported'));

export default function Router() {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1;
  const isSamsungbrowser = ua.indexOf('samsungbrowser') > -1;

  const params = window.location.search;

  const routes = [
    <Fragment key="router">
      <GlobalStyles />
      <FontFaces />
      <Suspense fallback={<div>Loading...</div>}>
        <ErrorBoundary>
          {(isAndroid && isSamsungbrowser) ? <Redirect to={`/notsupported${params}`} component={(props) => <NotSupported {...props} />} /> : ''}
          <Switch>
            {/* Homepage */}
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/scan" component={(props) => <ScanPage {...props} />} />
            <Route exact path="/info" component={(props) => <InfoPage {...props} />} />
            <Route exact path="/help" component={(props) => <HelpPage {...props} />} />
            <Route exact path="/usernotfound" component={UserNotFound} />
            <Route exact path="/notsupported" component={(props) => <NotSupported {...props} />} />
            {/* 404 - This must be the last route */}
            <Route
              component={(props) => {
              // check to see if malformated link has userid then redirect properly if it does
                const n = window.location.href.indexOf('userid');
                if (n === -1) {
                  return <NotFoundPage {...props} />;
                }
                const res = window.location.href.substr(n + 7, window.location.href.length - n - 7);
                return <Redirect to={`/?userid=${res}`} component={Dashboard} />;
              }}
            />
          </Switch>
        </ErrorBoundary>
      </Suspense>
    </Fragment>,
  ];
  return reactStrictMode && getEnvironment(window.location.hostname) !== 'production'
    ? <StrictMode>{ routes }</StrictMode>
    : routes;
}

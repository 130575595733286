import styled from 'styled-components';
import colors from 'theme/colors';

export const BadgeContainer = styled.div`
  left: 1.75em;
  position: absolute;
  text-align: center;
  top: 2em;

  p {
    font-size: .85em;
    font-weight: normal;
    margin-top: .25em;
  }

  &.is-locked {
    color: #ccc;

    p {
      position: relative;
      left: -.3em;
    }
  }
`;

export const Badge = styled.div`
  align-items: center;
  background: ${colors.brandPrimary};
  border: 1px solid ${colors.brandPrimary};
  border-radius: 50%;
  box-shadow: 0 1px 5px 1px rgba(60,64,67,.05), 0 1px 3px 1px rgba(60,64,67,.03);
  display: flex;
  justify-content: center;
  height: 45px;
  width: 45px;

  &.is-locked {
    background: transparent;
    border-color: #ccc;

    svg > * {
      stroke: #ccc;
    }
  }
`;

export const Image = styled.img`
  display: block;
  margin: 2em auto;
  max-width: 100%;
`;

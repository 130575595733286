import styled, { keyframes } from 'styled-components';
import colors from 'theme/colors';
import zIndex from 'theme/zIndex';

export const Navbar = styled.div`
  bottom: 0;
  padding: 0 3em .75em;
  position: fixed;
  right: 0;
  left: 0;
  z-index: ${zIndex.menu};
`;


export const pulse = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.22); }
`;

export const NavContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;

  a {
    margin: 1em;
  }
`;


export const TopItems = styled.div`
    flex-basis: 33.33%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    justify-content: center;
`;
export const ItemCounter = styled.div`
    color: ${colors.white};
    display: inline-flex;
    justify-content: space-around;
    padding-left: 25%;
`;


export const TopItem = styled.div`

  align-items: center;
  background: white;
  box-shadow: 0 1px 5px 3px rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.2);
  border-radius: 50%;
  color: ${colors.white};
  height: 25px;
  width: 25px;
  text-align: center;
  transition: background .5s ease;
  padding: 5px;
  margin: 5px;
  list-style: none;
  display: flex;


  &.is-large {
    height: 65px;
    width: 65px;

    svg {
      position: relative;
      top: -2px;
    }
  }

  &.is-active {
    transition: all 1s ease;
    animation: pulse .7s ease;
    animation-iteration-count: 2;
    background: ${colors.brandPrimary};
  }

  svg {
    transition: all 1s ease;
    animation: pulse .7s ease;
    animation-iteration-count: 2;
  }

`;
